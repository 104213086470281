<template>
  <div class="container">
    <van-cell-group class="mb10 header">
      <van-cell title="班级信息" />
      <van-cell title="班级名称：" :value="detail.class_name" />
      <van-cell title="授课老师：" :value="detail.teacher_name" />
      <van-cell title="课程类型：" :value="detail.class_type" />
      <van-cell title="开班时间：" :value="detail.class_start+' ~ '+detail.class_end" />
    </van-cell-group>
    <van-cell-group class="mb10">
      <van-cell title="班级学员" />
      <div v-if="studentList.length" class="studen-list">
        <div v-for="item in studentList" :key="item.student_id" class="studen-list-item">
          <div class="studen-list-item-media">
            <van-image :src="item.student_avatar" class="studen-list-item-media-img" />
          </div>
          <div class="studen-list-item-inner">
            <div class="studen-list-item-title"><span class="studen-list-item-icon" :style="{background:item.student_gender == 1 ? '#5499f6' : '#ffa6ce'}"><van-icon class-prefix="icon" color="#fff" size="12" :name="item.student_gender == 1 ? 'sex_man' : 'sex_woman'" /></span><span>{{ item.student_name }}</span></div>
            <div class="studen-list-item-txt">上课<span class="ml5 mr5">{{ item.student_book_count }}</span>次</div>
          </div>
          <div class="studen-list-item-ft">
            剩余 {{ item.left_times }} 课时
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        还没有学员
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import { Grid, GridItem } from 'vant'
import PageMixin from '@/mixins/page'

export default {
  name: 'TeacherClassesDetail',
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  mixins: [PageMixin],
  data() {
    return {
      detail: {},
      studentList: []
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.$api.employee_classesdetail({ classes_id: this.$route.query.class_id }).then(res => {
        this.detail = res.data
        this.studentList = res.data.class_students
      })
    }
  }
}
</script>
<style lang="less" scoped>

  .container{
    padding-top: 10px;
  }

  .header{
    /deep/ .van-cell__title{
      max-width: 90px;
    }
    /deep/ .van-cell__value{
      flex: 1;
      text-align: left;
    }
  }
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    background: #fff;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
    }
    &-title{
      max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }

  .studen-list{

  }

  .studen-list-item{
    box-sizing: border-box;
    position: relative;
    padding: 10px 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-media{
      margin-right: 10px;
      &-img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &-icon{
      background: #5499f6;
      color: #fff;
      display: inline-block;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      margin-right: 5px;
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
    &-ft{
      position: absolute;
      top: 20px;
      right: 15px;
      font-size: 14px;
      color: #666;
    }
  }

  .no-data{
    text-align: center;
    padding: 20px 15px;
    font-size: 14px;
    color: #999;
  }
</style>

